import Jumbotron from "../components/Jumbotron";

const Layout = ({ children }) => {
    return (
        <div>
            <Jumbotron style={{backgroundPosition:"top"}} title="Our Whanau" src={process.env.PUBLIC_URL + '/images/covid2.jpg'} />
            {children}
        </div>
    );
}

export default Layout;