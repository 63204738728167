import "../styles/components/SectionHeader.css";

const SectionHeader = ({ title, subtitle }) => (
    <div className="section-header">
        <hr />
        <p className="section-title">{title}</p>
        <hr />
    </div>
);

export default SectionHeader;