import "../styles/components/PartnerIcon.css";
const PartnerIcon = (partner) => {
    return (
        <div className="partner-icon" onClick={() => window.open(partner.url, "_blank")}>
            <img src={partner.img ? partner.img : "https://via.placeholder.com/150"} alt="Partner Logo" />
            <div className="tooltip">
                <p>{partner.name}</p>
                <p style={{textDecoration:"underline", color:"var(--primary)"}}>View Website</p>
            </div>
        </div>
    );
}

export default PartnerIcon;