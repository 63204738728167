import React from "react";
import "../styles/Rohe.css";
import Jumbotron from "../components/Jumbotron";
import FacebookEmbedPost from "../components/FacebookEmbedPost";

export default function Rohe() {
    return (
        <div>
            <Jumbotron style={{backgroundPosition:"top"}} title="Around the Rohe" src={process.env.PUBLIC_URL + '/images/covid2.jpg'} />
            <h1 style={{width:"100%", textAlign:"center"}}>News from Te Tai Tokerau</h1>
            <div class="rohe-container">
                <FacebookEmbedPost url="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fngatihinehealthtrust%2Fposts%2F527826002923079&show_text=true&width=500"></FacebookEmbedPost>
                <FacebookEmbedPost url="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fngatihinehealthtrust%2Fposts%2F525440079828338&show_text=true&width=500"></FacebookEmbedPost>
                <FacebookEmbedPost url="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FHeiwiKotahiTatouTrust%2Fposts%2F507313974992530&show_text=true&width=500"></FacebookEmbedPost>
                
                <FacebookEmbedPost url="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ftehikuhauora%2Fposts%2F1267858907842963&show_text=true&width=500"></FacebookEmbedPost>
                <FacebookEmbedPost url="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fhokiangahealth%2Fposts%2F1091386552576311&show_text=true&width=500"></FacebookEmbedPost>
                <FacebookEmbedPost url="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fhokiangahealth%2Fposts%2F1091349572580009&show_text=true&width=500"></FacebookEmbedPost>
                
                <FacebookEmbedPost url="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FNgapuhiIwiSocialServices%2Fposts%2F1268792137572206&show_text=true&width=500"></FacebookEmbedPost>
                <FacebookEmbedPost url="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FNgapuhiIwiSocialServices%2Fposts%2F1266208984497188&show_text=true&width=500"></FacebookEmbedPost>
                <FacebookEmbedPost url="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FNgapuhiIwiSocialServices%2Fposts%2F1263509301433823&show_text=true&width=500"></FacebookEmbedPost>
            
                <FacebookEmbedPost url="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fngatiwaitb%2Fposts%2F1043907977743637&show_text=true&width=500"></FacebookEmbedPost>
                <FacebookEmbedPost url="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ftehauawhiowhio%2Fposts%2F891474376348137&show_text=true&width=500"></FacebookEmbedPost>
                <FacebookEmbedPost url="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FTeRunangaoNgatiRehia%2Fposts%2F827188172892104&show_text=true&width=500"></FacebookEmbedPost>
            </div>
        </div>
    );
}