// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut} from "firebase/auth";
import {getFirestore,query,getDocs,getDoc,doc,setDoc,collection,where} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { ref, listAll, getDownloadURL, getStorage } from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAmLm0Q48H4Ve_4N9sG3CqXLx4o7agD4vI",
    authDomain: "te-rau-whiria-a5ff6.firebaseapp.com",
    projectId: "te-rau-whiria-a5ff6",
    storageBucket: "te-rau-whiria-a5ff6.appspot.com",
    messagingSenderId: "874338351678",
    appId: "1:874338351678:web:0ac00ae08fe9511d16af6f",
    measurementId: "G-7KS8VX2F7H"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);

export async function get_service_categories(){
    const categories = [];
    const q = query(collection(db, "service-categories"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        categories.push(doc.data());
    });
    return categories;
}