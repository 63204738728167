import React from "react";

// Components
import Jumbotron from "../components/Jumbotron";

class Error extends React.Component {
    render() {
        return (
            <div>
                <Jumbotron title="Page Not Found!" src={process.env.PUBLIC_URL + '/images/covid2.jpg'}/>
                <div style={
                    {
                        padding: "1em",
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                    }
                }>
                    <h2>Sorry, the page you are looking for does not exist.</h2>
                    <a href="javascript:history.back()">Go back</a>
                </div>
            </div>
        );
    }
}

export default Error;