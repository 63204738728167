import React from 'react';
import config from '../config.json';
import ReactGA from 'react-ga4';
const GA_TRACKING_ID = config.GA_TRACKING_ID;
ReactGA.initialize(GA_TRACKING_ID);

class GoogleAnalytics extends React.Component {
    componentDidUpdate({ location, history }) {
        const gtag = window.gtag;

        if (location.pathname === this.props.location.pathname) {
            // don't log identical link clicks (nav links likely)
            return;
        }
        window.setTimeout(() => {
            if (history.action === 'PUSH' &&
                typeof(gtag) === 'function') {
                gtag('config', GA_TRACKING_ID, {
                    'page_title': document.title,
                    'page_location': window.location.href,
                    'page_path': location.pathname
                });
            }
        }, 80); // wait 10ms to ensure title has changed.
    }

    render() {
        return null;
    }
}

export default GoogleAnalytics;