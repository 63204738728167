import "../styles/components/SideHeadingText.css";

const SideHeadingText = (props) => {
    return (
        <div className={'side-heading-text ' + props.className} style={props.style} id={props.id}>
            <div className="sht-heading">
                <h2>{props.subheading}</h2>
                <h1>{props.heading}</h1>
            </div>
            <div>
                {
                    props.text.split("\n").map((text, index) => {
                        return (
                            <p key={index}>{text}</p>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default SideHeadingText;