import React from "react";
import PartnerIcon from "../components/PartnerIcon";
import VideoJumbotron from "../components/VideoJumbotron";

import "../styles/Index.css";

class Index extends React.Component {
  render() {
    return (
      <div style={{marginBottom: "5em"}}>
        <VideoJumbotron />
        
        {/* Welcome to Whanau Ora */}
        <div className="flex-vertical text-section" style={{paddingTop: "1em", paddingBottom:"3em"}}>
          <div className="flex-vertical heading">
            <h2>WELCOME TO</h2>
            <h1 className="space-out">WHĀNAU ORA</h1>
          </div>
          <p>Whānau Ora is a Kaupapa Maori (culturally) based, whānau-centred approach to wellbeing which focuses on whānau being the decision-makers of their own aspirational journeys. 
            <br /><br />
            Building on existing strengths and capabilities, whanau are connected to specialist navigators (Kaiarahi) to assist them in accessing services required to achieve better whanau outcomes creating positive and lasting change. As a result, whanau are able to fully realise their potential, be self-determining and are empowered to continue to dream big!</p>
        </div>

        {/* Putting Whanau at the center */}
        <div className="section primary">
          <img className="whanau-img" src={process.env.PUBLIC_URL + "/images/covid3.jpg"} alt="Whanau Ora" style={{width: "100%"}} />
          <div className="flex-horizontal text-section" style={{padding: "7em 3em"}}>
            <div className="heading">
                <h2>PUTTING WHĀNAU</h2>
                <h1>AT THE CENTER</h1>
            </div>
            <p>
              Changing the paradigm of providing 'services to individuals' to placing 'whanau at the centre' means that service design and delivery is unique to each whanau as they are able to make their own decisions and find solutions to achieve their goals. This holistic, wrap around approach empowers whanau to be self-managing build their own capacity and resilience, with positive and lasting benefits.
              <br /><br />
              Moreover, the whanau-centered approach promotes a more holistic view of well-being, recognizing that the challenges faced by individuals are often interconnected and require comprehensive solutions. By considering the broader context of whanau dynamics, relationships, and community networks, service providers can better address the underlying causes of issues and work towards sustainable outcomes. This approach supports whanau in building their resilience and capacity not only to overcome immediate obstacles but also to thrive in the long term.
            </p>
          </div>
        </div>
        
        <br /><br />
        
          <div className="flex-vertical heading center">
              <h2>OUR</h2>
              <h1>PARTNERS</h1>
          </div>
          <div className="section">
            <div className="partners">
              <PartnerIcon
                name="Ngati Hine Health Trust"
                url="https://nhht.co.nz"
                img={process.env.PUBLIC_URL + "/images/partners/nhht.png"}
              />
              <PartnerIcon 
                img={process.env.PUBLIC_URL + "/images/partners/thh.png"}
                url="https://www.tehikuhauora.nz/"
                name="Te Hiku Hauora"
              />
              <PartnerIcon
                img="https://images.squarespace-cdn.com/content/v1/58fab315e6f2e151cda1d676/1492829247229-3MDKP1CVDPBEPA3W2EEP/KAONT.png?format=1500w"
                url="https://www.kaont.co.nz/"
                name="Kia a Ora Ngatiwai"
              />
              <PartnerIcon
                img={process.env.PUBLIC_URL + "/images/partners/thoon.png"}
                url="https://ngapuhi.iwi.nz/thoon/"
                name="Te Hau Ora o Ngapuhi"
              />
              <PartnerIcon
                img={process.env.PUBLIC_URL + "/images/partners/niss.png"}
                url="https://ngapuhi.iwi.nz/niss/"
                name="Ngapuhi Iwi Social Services"
              />
              <PartnerIcon
                img={process.env.PUBLIC_URL + "/images/partners/hhet.png"}
                url="https://www.hokiangahealth.org.nz/"
                name="Hokianga Health Enterprise Trust"
              />
              <PartnerIcon
                img={process.env.PUBLIC_URL + "/images/partners/tram.png"}
                url="https://www.terarawa.iwi.nz/about/te-rarawa-anga-mua/"
                name="Te Rarawa Anga Mua"
              />
              <PartnerIcon
                img={process.env.PUBLIC_URL + "/images/partners/hiktt.png"}
                url="https://heiwi.co.nz/"
                name="He Iwi Kotahi Tatou Trust"
              />
              <PartnerIcon
                img={process.env.PUBLIC_URL + "/images/partners/thaoo.png"}
                url="https://otangarei.org/"
                name="Te Hau Awhiowhio o Otangarei Trust"
              />
              <PartnerIcon
                img={process.env.PUBLIC_URL + "/images/partners/nkshs.png"}
                url="https://ngatikahu.org.nz/"
                name="Ngati Kahu Social & Health Services"
              />
              <PartnerIcon
                img={process.env.PUBLIC_URL + "/images/partners/trow.jpg"}
                url="https://whaingaroa.iwi.nz/"
                name="Te Runanaga o Whaingaroa"
              />
              <PartnerIcon
                img={process.env.PUBLIC_URL + "/images/partners/wwop.png"}
                url="wop.co.nz"
                name="Whakawhiti Ora Pai"
              />
              <PartnerIcon
                img={process.env.PUBLIC_URL + "/images/partners/nr.jpg"}
                url="https://ngatirehia.co.nz/"
                name="Ngati Rehia"
              />

              
            </div>
            <div className="flex-horizontal text-section">
              <p>The Te Tai Tokerau Whānau Ora Collective is made up of 13-multi-sectorial Kaupapa Maori Organisations (KMO) across Te Tai Tokerau. TTTWOC delivers an extensive range of integrated services from Ruakaka in the South, to Te Hapua in the Far North. 
              <br /><br />Our Partner providers have bases in the Far North (Kaitaia, Whaingaroa, Te Kao, Te Hapua,) the mid-North (Moerewa, Kawakawa, Kaikohe, Hokianga) and Whangarei region (Whangarei south, central, East Coast, Kamo and Otangarei). 
              <br /><br />The collective employs upwards of 800+ staff and contractors across our network and delivers on 60+ service contracts.Our Partners are based in their respective communities which makes them specialists in local knowledge. They are responsive to community need when it arises, which allows for a solutions focused lens to be cast that encourages innovative thinking when it comes to providing local support.</p>
            </div>
            <div className="heading"><p></p><h1>WHERE TO FIND US</h1></div>
            <iframe class="map-view" src="https://www.google.com/maps/d/embed?mid=1Gn565W3WpCOsqU6oEwmvPKzcg_rbN68B&ehbc=2E312F&wheel=false" width="640" height="480"></iframe>
        </div>

      </div>
    );
  }
}

export default Index;