import "../styles/components/Jumbotron.css";

const Jumbotron = (props) => {
    return (
        <div className="jumbotron" style={{...{"--bgi": `url(${props.src})`}, ...props.style}} id={props.id}>
            <div class="flex-vertical">
                <img src={process.env.PUBLIC_URL + "/images/s2-1.svg"} alt="covid" />
                <img src={process.env.PUBLIC_URL + "/images/s2-1.svg"} alt="covid" />
            </div>
            <h1>{props.title}</h1>
        </div>
    );
}

export default Jumbotron;
    