import React from "react";
import {BsFillTelephoneFill} from "react-icons/bs";
import {MdEmail} from "react-icons/md";

import "../styles/components/ServiceItem.css";

class ServiceCategoryItem extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <a className="service-item" href={`/services/category/${this.props.name}`}>
                <img src={this.props.img ? this.props.img : process.env.PUBLIC_URL + "/images/covid3.jpg"} alt="service" />
                <div className="service-item__details">
                    <h1>{this.props.name}</h1>
                    <p>{this.props.description}</p>
                    {/*<a>Learn More</a>*/}
                </div>
            </a>
        )
    }
}

export default ServiceCategoryItem;