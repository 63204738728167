import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Category from './Category';
const ServiceRoutes = () => {
    return (
        <Routes>
            <Route title="Services" path="/category/:category" element={<Category />} />
        </Routes>
    );
}

export default ServiceRoutes;