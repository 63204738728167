import React from 'react';


function Category() {
    const CATEGORY = window.location.pathname.split('/').pop();
    return (
        <div>
            <h1>{CATEGORY}</h1>
            
            {/* Add your content here */}
        </div>
    );
}

export default Category;
