// This may seem dumb to use a component for a single video, but we may need to edit it later for use with YouTube videos...

import React from 'react';
import "../styles/components/VideoJumbotron.css";

class VideoJumbotron extends React.Component{
    render(){
        return(
            <div className="jumbotron-container">
                <video className='jumbotron-video' src={process.env.PUBLIC_URL + "/videos/WhanauOraWebBanner.mp4"} autoPlay loop muted />
                <h1>TE TAI TOKERAU <span style={{color:"var(--primary)", fontWeight:600}}>WHANAU ORA COLLECTIVE</span></h1>
            </div>
        )
    }
}

export default VideoJumbotron;