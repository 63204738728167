import React from "react";

// styles
import "../styles/components/Phone.css";

export default class Phone extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="phone-container">
                <img className="phone" src={this.props.src} alt="Phone" />
            </div>
        );
    }
}
