import React from "react";
import "../styles/components/SearchInput.css";

import {FiSearch} from "react-icons/fi";

class SearchInput extends React.Component {
  render() {
    return (
      <div className="search-input">
        <FiSearch className="search-icon" />
        <p className="divider">|</p>
        <input
          type="text"
          placeholder={this.props.placeholder || "Search"}
          value={this.props.value}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

export default SearchInput;