import React from "react";
import "../styles/components/Footer.css";
import config from "../config.json";

const PUBLIC_DOMAIN_DATE = 2023 + 51;

class Footer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            hide_footer: false
        }
        this.hide = this.hide.bind(this);
        // Run the hide function to determine if the footer should be hidden

    }

    onComponentDidMount(){
        console.log("Footer Mounted");
    }

    createCopyrightString(){
        /* This function is used to create the footer copyright string */
        let current_year = new Date().getFullYear();
        if(current_year >= PUBLIC_DOMAIN_DATE){
            return `© ${PUBLIC_DOMAIN_DATE} Public Domain`;
        } else{
            return `© ${current_year} Ngati Hine Health Trust`;
        }
    }

    hide(){
        /* This function is used to hide the footer on certain pages */
        let path = window.location.pathname;
        config["hide_header-footer"].forEach((hide_path) => {
            console.log(hide_path === path);
            if(path == hide_path){ // Exact Match
                this.setState({hide_footer: true});
                //return;
            }

            if(hide_path.includes("*")){ // Wildcard Match
                let path_parts = hide_path.split("*");
                if(path.startsWith(path_parts[0])){
                    if(path.endsWith(path_parts[1])){
                        this.setState({hide_footer: true});
                        //return;
                    }
                }
            }
        });
        if(this.state.hide_footer){
            //this.setState({hide_footer: false});
        }
    }

    render(){
        console.log("Render",this.state.hide_footer);
        return (
            <footer className={this.state.hide_footer ? "hide" : ""}>
                <p>{this.state.hide_footer}</p>
                <div class="flex-horizontal">
                    <div className="footer-container">
                        <h1>Get in Touch</h1>
                        <p>Ngati Hine Health Trust</p>
                        <a href="tel:0800737573">0800 737 573</a>
                    </div>
                    <div className="footer-container" style={{width:"40%"}}>
                        <form>
                            <div>
                                <label htmlFor="name">Name</label>
                                <input type="text" name="name" id="name" placeholder="Enter your name" />
                            </div>
                            <div>
                                <label htmlFor="email">Email / Phone Number</label>
                                <input type="email" name="email" id="email" placeholder="Enter your email or phone number" />
                            </div>
                            <div>
                                <label htmlFor="email">Postcode</label>
                                <input type="number" name="postcode" id="postcode" placeholder="Enter your postcode" maxLength={4} />
                            </div>
                            <div>
                                <label htmlFor="message">Message</label>
                                <textarea name="message" id="message" placeholder="Enter your message"></textarea>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="copyright">
                    <p>{this.createCopyrightString()}</p>
                    <p>Website by <a href="https://www.linkedin.com/in/waimate-wihongi-b54024247/" target="_blank">Waimate Wihongi</a></p>
                </div>
            </footer>
        )
    }
}

export default Footer;