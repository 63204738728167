import React from "react";
import Layout from "../_Layout";

import "../../styles/Whanau.css"

class Story extends React.Component{
    render(){
        return(
            <div class="story-page">
                <div class="cover-img">
                    <h1>Our Story</h1>
                </div>
                <div className="story-content">
                    <p className="headline">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                    <div className="body">
                        <div className="section">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae nisl euismod, aliquam nisl quis, ultricies nisl. Donec euismod, nisl vitae aliquam ultricies, nisl nisl aliquam nisl, vitae aliquam nisl nisl vitae nisl. Donec euismod, nisl vitae aliquam ultricies, nisl nisl aliquam nisl, vitae aliquam nisl nisl vitae nisl. Donec euismod, nisl vitae aliquam ultricies, nisl nisl aliquam nisl, vitae aliquam nisl nisl vitae nisl. Donec euismod, nisl vitae aliquam ultricies, nisl nisl aliquam nisl, vitae aliquam nisl nisl vitae nisl. Donec euismod, nisl vitae aliquam ultricies, nisl nisl aliquam nisl, vitae aliquam nisl nisl vitae nisl. Donec euismod, nisl vitae aliquam ultricies, nisl nisl aliquam nisl, vitae aliquam nisl nisl vitae nisl. Donec euismod, nisl vitae aliquam ultricies, nisl nisl aliquam nisl, vitae aliquam nisl nisl vitae nisl.</p>
                        </div>
                        <div className="section">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae nisl euismod, aliquam nisl quis, ultricies nisl. Donec euismod, nisl vitae aliquam ultricies, nisl nisl aliquam nisl, vitae aliquam nisl nisl vitae nisl. Donec euismod, nisl vitae aliquam ultricies, nisl nisl aliquam nisl, vitae aliquam nisl nisl vitae nisl. Donec euismod, nisl vitae aliquam ultricies, nisl nisl aliquam nisl, vitae aliquam nisl nisl vitae nisl. Donec euismod, nisl vitae aliquam ultricies, nisl nisl aliquam nisl, vitae aliquam nisl nisl vitae nisl. Donec euismod, nisl vitae aliquam ultricies, nisl nisl aliquam nisl, vitae aliquam nisl nisl vitae nisl. Donec euismod, nisl vitae aliquam ultricies, nisl nisl aliquam nisl, vitae aliquam nisl nisl vitae nisl. Donec euismod, nisl vitae aliquam ultricies, nisl nisl aliquam nisl, vitae aliquam nisl nisl vitae nisl.</p>
                            <img src="https://via.placeholder.com/150" alt="placeholder" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Story;