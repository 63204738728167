import React from "react";
import "../styles/components/Header.css";
import config from "../config.json";

function hide(){
    return
    let path = window.location.pathname;
    config["hide_header-footer"].forEach((hide_path) => {
        if(path === hide_path){ // Exact Match
            console.log("false");
            return false;
        }

        if(hide_path.includes("*")){ // Wildcard Match
            let path_parts = hide_path.split("*");
            if(path.startsWith(path_parts[0])){
                if(path.endsWith(path_parts[1])){
                    return false;
                    console.log("false");
                }
            }
        }
    });
    return true;
}

const Header = (props) => {
    return (
        <header scroll={props.initScroll ? "true" : "false"} className={props.block && "header-block"}>
            <a href="/" className="logo">
                <img src={process.env.PUBLIC_URL + "/images/logoHalf.png"} alt="logo" />
                <div className="logo-text">
                    <h1>TE RAU <span style={{fontWeight: 600, color:"var(--primary)"}}>WHIRIA</span></h1>
                    <h5>Bringing together many resources {props.scrolled}</h5>
                </div>
            </a>
            <nav>
                <ul>
                    {/*<li><a href="/">Home</a></li>*/}
                    <li><a href="/about-us">About Us</a></li>
                    <li><a href="/rohe">Around the Rohe</a></li>
                    <li><a href="/services">Our Services</a></li>
                    <li><a href="/tuponotia">Tuponotia</a></li>
                    <li><a href="/gallery">Gallery</a></li>
                    {/*<li><a href="/contact-us">Download App</a></li>*/}
                </ul>
            </nav>
        </header>
    );
};

const MobileHeader = (props) => {
    return (
        <header className="mobile" scroll={props.initScroll ? "true" : "false"}>
            <p
                style={{color: "var(--primary)", zIndex: 10, position: "fixed", top: "0.3em", left: "1em", cursor: "pointer", fontSize: "1.5em", transform:"rotate(90deg)"}}
                onClick={() => document.querySelector(".header-mobile-selector").classList.toggle("active")}
            >|||</p>
            <a href="/" className="logo mobile">
                <img src={process.env.PUBLIC_URL + "/images/logoHalf.png"} alt="logo" />
                <div className="logo-text">
                    <h1>TE RAU <span style={{fontWeight: 600, color:"var(--primary)"}}>WHIRIA</span></h1>
                </div>
            </a>
            <nav className="header-mobile-selector"
                    onClick={() => document.querySelector(".header-mobile-selector").classList.toggle("active")}>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/about-us">About Us</a></li>
                    <li><a href="/rohe">Around the Rohe</a></li>
                    <li><a href="/services">Our Services</a></li>
                    <li><a href="/tuponotia">Tuponotia</a></li>
                    <li><a href="/gallery">Gallery</a></li>
                    {/*<li><a href="/contact-us">Download App</a></li>*/}
                </ul>
            </nav>
        </header>
    );
}

const ExportHeader = () => {
    if(hide()){
        return null;
    }

    let init_scroll = false;
    let spacer = true;
    
    if(window.location.pathname === "/") { // If we're on the home page, we want to hide the header
        window.addEventListener("scroll", () => {
            document.querySelector("header").setAttribute("scroll", window.scrollY > 0);
        });
        spacer = false;
    } else {
        init_scroll = true;
    }

    if (window.innerWidth >= 1053) {
        return spacer ? <div style={{height:"5em"}}><Header initScroll={init_scroll} /></div> : <Header initScroll={init_scroll} />;
    } else {
        return <MobileHeader initScroll={init_scroll} />;
    }
};

export default ExportHeader;