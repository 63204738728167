import React from "react";
import "../../styles/Whanau.css"

import Layout from "../_Layout";
import SectionHeader from "../../components/SectionHeader";

class Whanau extends React.Component{
    render(){
        return (
            <Layout>
                <div className="whanau-container">
                    <SectionHeader title="Whānau Stories" />
                    <div className="whanau-stories-container">
                        <a href="/whanau/story?id=1" className="whanau-story">
                            <img src={process.env.PUBLIC_URL + '/images/covid2.jpg'} alt="Whanau" />
                            <div className="ws-content">
                                <h1>FEATURED STORY</h1>
                                <p className="ws-date">16/08/2023</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam lacus tortor, laoreet ac odio et, lacinia lacinia nulla. Nam ut lacus quis quam venenatis laoreet a ac metus. Ut nec vestibulum lectus. Nullam nec vestibulum erat. Integer in faucibus ligula. Aenean mollis faucibus ipsum, in faucibus leo viverra a. Sed nec vulputate mi, non rhoncus nibh. Cras efficitur erat ut ligula condimentum luctus. Duis lobortis enim rutrum orci ultricies faucibus. Sed efficitur ipsum ut maximus vestibulum. Donec volutpat et turpis id sollicitudin. Sed sollicitudin dictum auctor. Donec luctus lorem nec tellus mattis consequat.</p>
                            </div>
                        </a>
                        <div className="ws-multi-cont">
                            <a className="whanau-story">
                                <img src={process.env.PUBLIC_URL + '/images/covid2.jpg'} alt="Whanau" />
                                <div className="ws-content">
                                    <h1>FEATURED STORY</h1>
                                    <p className="ws-date">16/08/2023</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam lacus tortor, laoreet ac odio et, lacinia lacinia nulla. Nam ut lacus quis quam venenatis laoreet a ac metus. Ut nec vestibulum lectus. Nullam nec vestibulum erat. Integer in faucibus ligula. Aenean mollis faucibus ipsum, in faucibus leo viverra a. Sed nec vulputate mi, non rhoncus nibh. Cras efficitur erat ut ligula condimentum luctus. Duis lobortis enim rutrum orci ultricies faucibus. Sed efficitur ipsum ut maximus vestibulum. Donec volutpat et turpis id sollicitudin. Sed sollicitudin dictum auctor. Donec luctus lorem nec tellus mattis consequat.</p>
                                </div>
                            </a>
                            <div className="whanau-story">
                                <img src={process.env.PUBLIC_URL + '/images/covid2.jpg'} alt="Whanau" />
                                <div className="ws-content">
                                    <h1>FEATURED STORY</h1>
                                    <p className="ws-date">16/08/2023</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam lacus tortor, laoreet ac odio et, lacinia lacinia nulla. Nam ut lacus quis quam venenatis laoreet a ac metus. Ut nec vestibulum lectus. Nullam nec vestibulum erat. Integer in faucibus ligula. Aenean mollis faucibus ipsum, in faucibus leo viverra a. Sed nec vulputate mi, non rhoncus nibh. Cras efficitur erat ut ligula condimentum luctus. Duis lobortis enim rutrum orci ultricies faucibus. Sed efficitur ipsum ut maximus vestibulum. Donec volutpat et turpis id sollicitudin. Sed sollicitudin dictum auctor. Donec luctus lorem nec tellus mattis consequat.</p>
                                </div>
                            </div>
                            <a className="whanau-story">
                                <img src={process.env.PUBLIC_URL + '/images/covid2.jpg'} alt="Whanau" />
                                <div className="ws-content">
                                    <h1>FEATURED STORY</h1>
                                    <p className="ws-date">16/08/2023</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam lacus tortor, laoreet ac odio et, lacinia lacinia nulla. Nam ut lacus quis quam venenatis laoreet a ac metus. Ut nec vestibulum lectus. Nullam nec vestibulum erat. Integer in faucibus ligula. Aenean mollis faucibus ipsum, in faucibus leo viverra a. Sed nec vulputate mi, non rhoncus nibh. Cras efficitur erat ut ligula condimentum luctus. Duis lobortis enim rutrum orci ultricies faucibus. Sed efficitur ipsum ut maximus vestibulum. Donec volutpat et turpis id sollicitudin. Sed sollicitudin dictum auctor. Donec luctus lorem nec tellus mattis consequat.</p>
                                </div>
                            </a>
                            <a className="whanau-story">
                                <img src={process.env.PUBLIC_URL + '/images/covid2.jpg'} alt="Whanau" />
                                <div className="ws-content">
                                    <h1>FEATURED STORY</h1>
                                    <p className="ws-date">16/08/2023</p>
                                    <p>Lorem ipsum dolor sit amet,</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Whanau;