import React from "react";

// components
import Jumbotron from "../components/Jumbotron";
import Phone from "../components/Phone";

// styles
import "../styles/Tuponotia.css";

export default function Tuponotia() {
    return (
        <div>
            <Jumbotron style={{backgroundPosition:"top"}} title="Tuponotia" src={process.env.PUBLIC_URL + '/images/tuponotia1.jpg'} />
            <h1 className="tuponotia-heading">Tuponotia</h1>
            <h3 className="tuponotia-subheading">Whānau Ora App</h3>
            <div className="tuponotia-divider"></div>

            <div className="tuponotia-download-container">
                <a className="tuponotia-button" href="https://drive.google.com/file/d/16J_HaPea99DpDeWxE8SjmCiyqsdmv-MT/view?usp=sharing" download>
                    <img src={process.env.PUBLIC_URL + '/images/android.svg'} alt="Google Play Store" />
                    Available on Android
                </a>
            </div>
            <div className="tuponotia-split-container">
                <div className="tuponotia-sc-text">
                    <h3 className="tuponotia-subheading">GET CONNECTED
                    </h3>
                    <p className="tuponotia-paragraph">Tuponotia is a mobile application designed to help whānau access services and resources in their local area. The app is designed to be easy to use and accessible to all.</p>
                    <p className="tuponotia-paragraph"></p>
                    
                </div>
                <Phone src={process.env.PUBLIC_URL + '/images/tuponotia/splash.png'} />
            </div>
            <div className="tuponotia-split-container">
                <Phone src={process.env.PUBLIC_URL + '/images/tuponotia/serviceprovider.png'} />
                <div className="tuponotia-sc-text">
                    <h3 className="tuponotia-subheading">DEDICATED SERVICE DIRECTORY</h3>
                    <p className="tuponotia-paragraph">
                        Keep up to date with TTTWOC and see what our service providers. View all our services around Te Tai Tokerau!
                    </p>
                    <p className="tuponotia-paragraph">
                        
                    </p>
                </div>
            </div>
        </div>
    );
}